.right-bar {
  display: flex;
  /* flex: 1; */
  width: 400px;
  flex-shrink: 0;
  flex-direction: column;
  background-color: antiquewhite;
  font-size: 4rem;
  font-family: "akira";
  justify-content: center;
  overflow: hidden;
  pointer-events: none;
  /* max-height: 100vh; */
}

.right-bar__text {
  height: 90vh;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  animation: marquee 4s linear infinite;
}

.right-footer {
  display: flex;
  flex-direction: row;
  position: fixed;
  border-top: 3px solid black;
  justify-content: center;
  align-items: center;
  font-family: "roslindale";
  left: 0;
  bottom: 0;
  width: 34%;
  height: 3rem;
  background-color: cornflowerblue;
  font-size: 1.3rem;
  text-align: center;
}

.footer-text {
  padding: 0 1rem;
  cursor: pointer;
  color: white;
}

.footer-text:hover {
  color: rgb(241, 199, 3);
}

@keyframes marquee {
  from {
    margin-top: -100%;
  }
  to {
    margin-top: 47%;
  }
}

@media screen and (max-width: 1450px) {
  .right-bar {
    width: 300px;
    font-size: 3rem;
  }

  .right-bar__text {
    animation: marquee 3s linear infinite;
  }

  @keyframes marquee {
    from {
      margin-top: -70%;
    }
    to {
      margin-top: 40%;
    }
  }
}

@media screen and (max-width: 1199px) {
  .right-bar {
    width: 200px;
    font-size: 2rem;
  }

  .right-bar__text {
    animation: marquee 2s linear infinite;
  }

  @keyframes marquee {
    from {
      margin-top: -70%;
    }
    to {
      margin-top: 40%;
    }
  }
}

@media screen and (max-width: 950px) {
  .right-bar {
    width: 11px;
    font-size: 0.7rem;
  }

  .right-bar__text {
    writing-mode: vertical-lr;
    text-orientation: mixed;
    align-items: flex-end;
    white-space: nowrap;
    animation: marquee 2s linear infinite;
  }

  @keyframes marquee {
    from {
      margin-top: 600%;
    }
    to {
      margin-top: -2000%;
    }
  }

  .right-footer {
    display: none;
  }
}
