.content {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  background-color: beige;
  font-family: "roslindale";
  overflow: scroll;
  border-left: 3px solid black;
  overflow-x: hidden;
}

.top-bar {
  display: flex;
  flex-direction: row;
  z-index: 4;
  position: sticky !important;
  position: -webkit-sticky;
  top: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: cornflowerblue;
  border-bottom: 3px solid black;
}

.top-bar__left,
.top-bar__right {
  display: flex;
  font-size: 1rem;
  color: #fff;
  cursor: pointer;
}

.bar-text:hover {
  color: rgb(241, 199, 3);
}

.bar-text {
  padding-left: 1rem;
  padding-right: 1rem;
  text-decoration: none;
  color: white;
}

.top-bar__right {
  padding-left: 1rem;
}

.top-bar__left {
  padding-right: 1rem;
}

.top-bar__medium {
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  color: #fff;
  cursor: pointer;
  padding-bottom: 0.6rem;
}

.top-bar__title {
  text-align: center;
  align-items: center;
  text-decoration: none;
  font-size: 3rem;
  color: #000;
}

.top-bar__burger {
  position: absolute;
  right: 1rem;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: flex-end;
}

.nav-item {
  height: 80px;
  border-bottom: 2px solid transparent;
}

.nav-item:hover {
  border-bottom: 2px solid #f00946;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.img-ctn {
  display: flex;
}

.content-first {
  align-self: center;
  width: 100%;
  height: auto;
}

.new-album-ctn {
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: cornflowerblue;
  color: #fff;
}

.new-album-title {
  display: flex;
  font-size: 1.6rem;
}

.order-button {
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
  background-color: bisque;
  padding: 1rem 2.5rem;
  color: black;
  cursor: pointer;
}

.order-buttom:hover {
  background-color: cornflowerblue;
}

.new-album__video-ctn {
  padding-top: 1rem;
}

.tour-section {
  display: flex;
  flex-direction: column;
  background-color: blanchedalmond;
  align-items: center;
  padding: 5rem 2rem;
}

.tour-section__title {
  font-size: 2rem;
  padding-bottom: 2rem;
}

table,
th,
td {
  border: 1px solid black;
}

th {
  background-color: cornsilk;
}

td:hover {
  background-color: blanchedalmond;
}

table {
  background-color: white;
}

.bottom-footer {
  display: flex;
  position: sticky;
  border-top: 3px solid black;
  bottom: 0;
  justify-content: space-between;
  align-items: center;
  font-family: "comic";
  padding: 0.9rem 1rem;
  background-color: cornflowerblue;
}

.bottom-footer__text1 {
  padding-left: 1rem;
}

.bottom-footer__text2 {
  padding-right: 1rem;
}

@keyframes silly {
  from {
    margin-top: 0%;
  }
  to {
    margin-top: 3%;
  }
}

@keyframes sillyMobile {
  from {
    left: -3%;
  }

  to {
    left: 3%;
  }
}

@media screen and (max-width: 1199px) {
  .top-bar {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .top-bar__title {
    font-size: 2rem;
    padding-bottom: 0.25rem;
  }
}

@media screen and (max-width: 500px) {
  .top-bar {
    position: relative;
    flex-direction: row;
    padding-top: 0.7rem;
    padding-bottom: 0.2rem;
  }

  .top-bar__title {
    justify-content: center;
    align-items: center;
  }

  .new-album__video-ctn {
    padding-top: 2rem;
  }

  .new-album-title {
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
  }

  .bottom-about:hover {
    animation: none;
  }

  .bottom-about {
    animation-direction: alternate;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 86.5vh;
    position: absolute;
    top: 2.65rem;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: cornflowerblue;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
    overflow: hidden;
  }

  .nav-menu.iphone {
    background: cornflowerblue;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
    height: 73vh;
    overflow: hidden;
  }

  .nav-links {
    display: flex;
    align-self: center;
    transform: translate(29%, -50%) !important;
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 100%;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
