body {
  margin: 0;
  min-height: -webkit-fill-available;
  max-height: 100vh;
}

html {
  min-height: -webkit-fill-available;
  max-height: 100vh;
}

@font-face {
  font-family: "akira";
  src: url(./fonts/Akira-Expanded-Demo.otf) format("opentype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "roslindale";
  src: url(./fonts/Roslindale-DeckNarrowBold-Testing.woff) format("woff");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "comic";
  src: url(./fonts/Qdbettercomicsans-jEEeG.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
