.App {
  display: flex;
  height: 100vh;
}

.iphone {
  display: flex;
  max-height: calc(100vh - 113px);
}

.chrome {
  display: flex;
  max-height: calc(100vh - 105px);
}
